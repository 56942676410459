<template>
  <div class="w-100 d-flex device flex-column">
    <div class="d-flex w-100 header">
      <div class="flex-1">设备编号</div>
      <div class="flex-1">设备名称</div>
      <div class="flex-1">详细位置</div>
      <div class="flex-1">设备状态</div>
    </div>
    <div class="w-100 h-100 list">
      <div class="d-flex w-100 header" v-for="(item, index) in list" :key="index">
        <div class="flex-1">{{item.deviceId}}</div>
        <div class="flex-1">{{item.productName}}</div>
        <div class="flex-1">{{item.detailAddress}}</div>
        <div class="flex-1">{{item.deviceStatus == 1 ? '正常' : item.deviceStatus == 2 ? '离线' : item.deviceStatus == 3 ? '预警' : item.deviceStatus == 4 ? '火警' : '故障'}}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: []
    }
  },
  props: ['info'],
  watch: {
    info: {
      handler() {
        this.getList();
      },
      deep:true
    }
  },
  created () {
    this.getList();
  },
  methods: {
    getList () {
      this.$get(this.$apis.deviceList, {
        pageNum: 1,
        pageSize: 1000,
        floorName: this.info.name,
        status: 0,
        latitude: this.info.latitude,
        longitude: this.info.longitude,
        towerId: this.info.towerId,
        townId: this.info.townId
      }).then(res => {
        if (res && res.code === 0) {
          this.list = res.data.list;
        }
      });
    }
  }
}
</script>

<style lang="scss" scoped>
.device {
  height: 5rem;
  overflow: hidden;
  ::-webkit-scrollbar {
    display: none !important;
    width: 0 !important;
  }
  .header {
    text-align: center;
    color: #333;
    font-size: 0.25rem;
    line-height: 0.5rem;
  }
  .list {
    overflow: auto;
  }
}
</style>