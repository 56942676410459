<template>
  <div class="w-100 h-100">
    <Echart
      :options="options"
      chartId="statisticsChart"
      height="100%"
      width="100%"
    ></Echart>
  </div>
</template>

<script>
import Echart from '@/common/echart'
export default {
  data () {
    return {
      options: {},
    };
  },
  components: {
    Echart,
  },
  props: {
    cdata: {
      type: Object,
      default: () => ({})
    },
  },
  watch: {
    cdata: {
      handler (newData) {
        this.options = {
          color: [
            "#FF4B34",
            "#FFC700",
            "#999999",
            "#FC7B4F"
          ],
          tooltip: {
            show: true,
            confine: true,
            trigger: 'item',
            formatter: function (params) {
              var res = `${params.marker}${params.data.name}设备: ${params.data.value}%`;
              return res;
            }
          },
          legend: {
            orient: "vertical",
            icon: "circle",
            top: 0,
            right: '20%',
            data: newData.xData,
            textStyle: {
              color: "#11d0c9"
            }
          },
          series: [
            {
              name: "设备情况月统计",
              type: "pie",
              radius: ['50%', '80%'],
              center: ["30%", "50%"],
              data: newData.seriesData,
              label: {
                normal: {
                  formatter: function(e) {
                    if (e.data.value == 0) {
                      e.data.labelLine.show = false;
                      e.data.label.show = false;
                    }
                  }
                }
              }
            }
          ]
        }
      },
      immediate: true,
      deep: true
    }
  }
};
</script>

<style lang="scss" scoped>
</style>