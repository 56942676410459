<template>
  <div class="w-100 h-100">
    <Chart :cdata="cdata" />
  </div>
</template>

<script>
import Chart from './chart.vue';
export default {
  data () {
    return {
      cdata: []
    }
  },
  components: {
    Chart,
  },
  props: {
    data: {
      type: Array,
      default() {
        return []
      }
    }
  },
  watch: {
    data: {
      handler(newData) {
        var arr = [];
        newData.forEach(element => {
          arr.push({
            name: element.province.replace('省', '').replace('市', '').replace('自治区', ''),
            value: element.totalDevicesCount,
            elseData: [
              {name: '火警总数', value: element.totalFireAlarmDeviceCount},
              {name: '故障总数', value: element.totalMalfunctionDeviceCount}
            ]
          })
        });
        this.cdata = arr;
      },
      immediate: true,
      deep: true,
    }
  },
  mounted () {
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
</style>