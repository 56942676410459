<template>
  <div id="statistics">
    <div class="bg-color-black d-flex flex-column">
      <div class="d-flex py-2 pl-2">
        <span style="color:#5cd9e8">
          <icon name="chart-pie"></icon>
        </span>
        <div class="d-flex">
          <span class="text mx-2">设备情况月统计</span>
          <dv-decoration-1 style="width:1.25rem;height:.25rem;position:relative;top:-.0375rem;" />
        </div>
      </div>
      <div class="d-flex jc-center flex-1 h-100">
        <statisticsChart :cdata="cdata" />
      </div>
    </div>
  </div>
</template>

<script>
import statisticsChart from "@/components/echart/statisticsChart";
export default {
  data() {
    return {
      cdata: {
        xData: ["火警", "故障", "离线", "预警"],
        seriesData: []
      }
    };
  },
  components: {
    statisticsChart
  },
  mounted() {
    
  },
  props: {
    data: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  watch: {
    data: {
      handler(newData) {
        this.cdata.seriesData = [
          { value: Math.round((newData.totalFireAlarmDeviceCount/newData.totalEventCount)*100), name: "火警", labelLine: {show: true}, label: {show: true} },
          { value: Math.round((newData.totalMalfunctionDeviceCount/newData.totalEventCount)*100), name: "故障", labelLine: {show: true}, label: {show: true} },
          { value: Math.round((newData.totalOfflineDeviceCount/newData.totalEventCount)*100), name: "离线", labelLine: {show: true}, label: {show: true} },
          { value: Math.round((newData.totalEarlyWarningDeviceCount/newData.totalEventCount)*100), name: "预警", labelLine: {show: true}, label: {show: true} }
        ]
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    
  }
};
</script>

<style lang="scss">
#statistics {
  padding: 0.2rem;
  width: 100%;
  height: 100%;
  border-radius: 0.0625rem;
  .bg-color-black {
    width: 100%;
    height: 100%;
  }
  .text {
    font-size: 0.2rem;
    color: #11d0c9;
  }
}
</style>