<template>
  <div id="centreLeft1">
    <div class="bg-color-black d-flex flex-column">
      <div class="data d-flex py-3 jc-between">  
        <dv-border-box-13>
          <div class="d-flex flex-column ai-start py-3 px-3">
            <div class="text">当前火警总数</div>
            <div class="text">{{fireAlarmCount}}</div>
          </div>
        </dv-border-box-13>
        <dv-border-box-13>
          <div class="d-flex flex-column ai-start py-3 px-3">
            <div class="text">当前故障总数</div>
            <div class="text">{{malFunctionCount}}</div>
          </div>
        </dv-border-box-13>
        <dv-border-box-13>
          <div class="d-flex flex-column ai-start py-3 px-3">
            <div class="text">当前设备总数</div>
            <div class="text">{{totalCount}}</div>
          </div>
        </dv-border-box-13>
      </div>
      <div class="d-flex jc-center flex-1 h-100">
        <chinaMapChart :data="data" />
      </div>
    </div>
  </div>
</template>

<script>
import chinaMapChart from "@/components/echart/chinaMapChart";
export default {
  data() {
    return {};
  },
  components: {
    chinaMapChart
  },
  props: {
    fireAlarmCount: {
      type: Number,
      default: 0
    },
    malFunctionCount: {
      type: Number,
      default: 0
    },
    totalCount: {
      type: Number,
      default: 0
    },
    data: {
      type: Array,
      default() {
        return []
      }
    }
  },
  mounted() {},
  methods: {}
};
</script>

<style lang="scss">
#centreLeft1 {
  padding: 0.2rem;
  width: 100%;
  height: 100%;
  border-radius: 0.0625rem;
  .bg-color-black {
    width: 100%;
    height: 100%;
    .data {
      &::before, &::after {
        content: '';
        display: block;
      }
      .text {
        color: #11d0c9;
        font-size: 0.3rem;
        line-height: 0.45rem;
      }
    }
  }
}
</style>